$bp-xlarge: 1800px;
$bp-large: 1400px;
$bp-desktop: 1100px;
$bp-medium: 800px;
$bp-small: 600px;
$bp-xsmall: 480px;



$color-light-grey: rgba(255, 255, 255, 0.8);  
$color-light-white: rgba(255, 255, 255, 1.0);  
$color-hilight: #33b5d5;
$color-hilight-active: #7ef0ff;

$color-facebook: #3b5999;
$color-twitter: #55acee;
$color-google-plus: #dd4b39;
$color-mail: #C92228;


$border-radius-dialog: 2px;  

$font-stack: 'Open Sans', sans-serif;
$basic-transition: all 0.2s ease-out;

$easing1: cubic-bezier(0.215, 0.61, 0.355, 1);
$easing2: cubic-bezier(.19,1,.22,1);
$easing3: cubic-bezier(0.86, 0, 0.07, 1);
$easing4: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$color-white: #FBF9F9;
$color-black: #333031;
$color-blue: #55CEE2;
$color-yellow: #F7BD0C;
$border-radius: 3px;