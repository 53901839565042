

* {
	box-sizing: border-box;
}

body {

	background-color: #212121;

}

.wrapper {
	max-width: 1200px;
	margin: 0px auto;
}
