.ai-preview  {
  position: absolute;
  top: 10%;
  left: 0;
  width: 33%;
  height: 95%;
  z-index: 1001;
  transition: $basic-transition;
  opacity: 0;
  

  &--show {
    opacity: 1.0;
    overflow-y: scroll;
    margin-right: 0px;
    z-index: 1001;


  }

  &--needs-scrolling {
    .ai-preview-list-column, .ai-preview-list-scrolling {
      -webkit-mask-image: -webkit-gradient(linear, left 90%, left bottom, from(rgba(darken($color-black, 10%),1)), to(rgba(darken($color-black, 10%),0)));
    }
  }

  &--fullscreen {
    .ai-preview-list-column, .ai-preview-list-scrolling {
      -webkit-mask-image: -webkit-gradient(linear, left 90%, left bottom, from(rgba(darken($color-black, 10%),1)), to(rgba(darken($color-black, 10%),0)));


    }
  }


  .ai-preview-list {
    position: absolute;
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    flex-direction: row;
    left: 6px;
    max-height: 90%;
    top: 0%;
    // height: 100%;
    overflow-y: scroll;
    margin-right: 0px;
    opacity: 0;
    transition: $basic-transition;

    .ai-preview-list-column {
      border-radius: $border-radius 0 0 $border-radius;

      min-width: 18px;
      overflow-y: scroll;
      height: inherit;

      background: rgba(darken($color-black, 10%), 0.55);
      

      &:first-child {
        transform: translateX(0.3px);

        border-radius:  $border-radius 0  0 $border-radius  !important
      }

      &:last-child {
        transform: translateX(-29px);
        border-radius: 0 $border-radius $border-radius 0;
      }
    }

    .ai-preview-list-scrolling {
      overflow-y: scroll;
      height: inherit;
      width: calc(100% - 12px);
      


      &::-webkit-scrollbar {
        width: 7px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        width: 7px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: transparent;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: rgba($color-white, .18);
        }
      }
    }

    .ai-preview-list-item {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      background: rgba(darken($color-black, 10%), 0.55);
      margin-bottom: 8px;
      margin-right: 22px;
      padding: 24px 0;

      &:last-child {
        margin-bottom: 0px;
      }

      .mask {
        width: 84px;
        border-radius: $border-radius;
        transition: border-color 0.3s $easing2;
        overflow: hidden;

        &:after {
          content: '';
          position: relative;
          display: block;
          width: 84px;
          height: 100%;
          opacity: 0.12;
          transition: opacity 0.3s $easing1;
          background: darken($color-black, 10%);
        }
        
      }

      .text {
        margin-left: 16px;

        i {
          margin-right: 8px;
          font-size: 16px;
        }

        h2, h3 {  
          transition: opacity 0.3s $easing1;

          span {
            width: 155px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }


        h2 {
          display: flex;
          align-items: center;
          line-height: 1.2;
          letter-spacing: 0.012em;
          font-size: 16px;     
          font-weight: 300;     
          color: $color-white;
          margin-bottom: 8px;
          // color: darken($color-white, 10%);
          opacity: 0.8;
          color: $color-white;
        }
        h3 {
          display: flex;
          align-items: center;
          font-weight: 300;   
          letter-spacing: 0.024em;  
          font-size: 15px;
          line-height: 1.3;
          // color: darken($color-white, 15%);
          opacity: 0.55;
          color: $color-white;
        }
      }

      &--person {

        .mask {
          height: 107px;
          &:after {
            transform: translateY(-107px);
            height: 107px;
          }
        }
      }
      &--music {

        .mask {
          height: 84px;
          &:after {
            transform: translateY(-84px);
            height: 84px;
          }
        }

      }
      &--product {

        .mask {
          height: 66px;
          &:after {
            transform: translateY(-66px);
            height: 66px;
          }
        }

      }
      &--trivia {
        input {
          display: none;

        }

        .mask {
          display: none;

        }

        .text {
          margin-left: 0;
          cursor: default;

          h2, h3 {

            // > div > div > div {
            //   display: inline;
            // }
            span {
              width: 310px;
              text-overflow: initial;
              white-space: normal;
              overflow: visible;
            }
            .more {
              display: flex;
              align-items: center;
              // justify-content: flex-end;
              margin-top: 9px;
              line-height: 1;
              text-transform: uppercase;
              font-weight: 400;
              font-size: 11px;
              letter-spacing: 0.06em;

              opacity: 0.8;
              transition: opacity 0.3s $easing1;

              i {
                line-height: 1.3;
                margin-left: 6px;
                font-size: 6px;
                margin-right: 9px;
              }

              &:hover {
                opacity: 1;
              }
            }
          }

          h2 {
            cursor: pointer;
          }
        }
      }

      &:hover {
        .text {
          h2 {
            opacity: 0.95;
          }
          h3 {
            opacity: 0.70;
          }
        }
        .mask {
          &:after {
            opacity: 0;
          }
        }
        .starred {
          .icon-star {
            display: block;
          }
          .icon-star-fill {
          }
        }

      }
    }
  }

  #toggleWikiPreview {
    display: none;

    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    ~label {
      cursor: pointer;
      width: 80px;
      height: 36px;
      border-radius: 36px;
      position: fixed;
      top: 20px;
      display: flex;
      align-items: center;
      
      .slit {
        border-radius: 8px;
        width: 100%;
        height: 18px;
        width: 100%;
        background: rgba($color-black, 0.12);
        border: solid 2px $color-white;
        transition: all 0.6s $easing4;
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 33px 100%, 33px 0);
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          letter-spacing: 0.12em;
          line-height: 0;
          font-weight: 700;
          text-transform: uppercase;
          color: rgba($color-white, .8);
          transform: translateX(42px);
        }
      }

      .circle {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        // box-shadow: -6px 0px 6px 0px rgba(darken($color-black, 10%), 0.24); 
        background: rgba($color-black, 0.12);
        border: solid 2px $color-white;
        position: absolute;
        transition: all 0.6s $easing4;
      }
    }

    &:checked {
      ~label {        
        .slit {
          background: linear-gradient(90deg, rgba($color-blue, 0.12), rgba($color-blue, 0.06) 60%, rgba($color-blue, 0) 0);
          border-color: $color-blue;
          clip-path: polygon(0% 0%, 0% 100%, 47px 100%, 47px 0, 100% 0, 100% 100%, 100% 100%, 100% 0%);
          // transition: all 0.6s $easing4;

          span {
            color: darken($color-blue, 0%);
            transform: translateX(14px);
          }
        }

        .circle {
          transform: translateX(44px);
          border-color: $color-blue;
          background: rgba($color-blue, 0.12);
          // transition: all 0.6s $easing4;
        }

        ~.ai-preview-list {
          opacity: 1.0;
        }
      }
    }
  }

  .ai-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    top: 20px;

    height: 36px;

    .divider {
      width: 1px;
      height: 18px;
      background: rgba($color-white, .80);
      margin-right: 20px;
    }

    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 36px;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      color: rgba($color-white, .80);
      margin-right: 8px;
      transition: all 0.2s $easing1; 
    }

    .ellipsis {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      // border: solid 1px rgba($color-white, .55);
      background: rgba($color-white, .55);
      margin-right: 3px;
      transition: all 0.3s $easing1; 
      opacity: 0;

      &:last-child {
        margin: 0;
      }

      &:nth-child(5) {
        transition-delay: 0.09s;
      }

      &:nth-child(4) {
        transition-delay: 0.18s;
      }

      &:nth-child(3) {
        transition-delay: 0.27s;
      }
    }

    &:hover {
      cursor: pointer;

      span {
        color: $color-white;
      }
      .ellipsis {
        opacity: 1;
        background-color: $color-white;

        &:nth-child(3) {
          transition-delay: 0.09s;
        }

        &:nth-child(4) {
          transition-delay: 0.18s;
        }

        &:nth-child(5) {
          transition-delay: 0.27s;
        }
      }
    }
  }

}

.ai-list-item {

  visibility: hidden;
  position: absolute; 
  left: -999em;
  height: 0px;

  
  &--show {
    visibility: visible;
    height: auto;
    left: auto;
    position: relative;
    margin: 0px 0px 10px 0px;

  }

  &:nth-child(3) {
          margin-bottom: 0px;
        }
  &:nth-child(7) {
          margin-bottom: 0px;
        }

   &:nth-child(14) {
          margin-bottom: 0px;
        }       

  &:last-child {
    margin-bottom: 0px;
  }


}

.ai-preview::-webkit-scrollbar { 
  display: none; 
}

@media only screen and (min-width: 1240px) {

 .ai-more {
  padding-left: 6px;
}


#toggleWikiPreview {


  ~label {

    margin-left: 24px;

  }

}



.ai-preview-list-column {

  &:first-child {
    transform: translateX(0.1px) !important;


  }

}



}

@media only screen and (min-width: 900px) {
  .ai-more {
    margin-left: 120px !important;
  }

  #toggleWikiPreview {
    ~label {
      // margin-left: 20px !important;
    }
  }
}


