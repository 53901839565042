/* SASS partial for grunt-webfont, autogenerated, do not edit! */
@font-face {
  font-family: "icons";
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('eot'),
    url('../fonts/icons.woff') format('woff'),
    url('../fonts/icons.ttf') format('truetype'),
    url('../fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconcss {
	font-family:"icons";
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

.iconcss {
	@include iconcss();
}

.icon-replay:before { content: "\EA01" }
.icon-drag-dots:before { content: "\EA02" }
.icon-expand:before { content: "\EA03" }
.icon-line-arrow-up:before { content: "\EA04" }
.icon-link-out:before { content: "\EA05" }
.icon-tick:before { content: "\EA06" }
.icon-block-quote:before { content: "\EA07" }
.icon-link:before { content: "\EA08" }
.icon-add:before { content: "\EA09" }
.icon-star:before { content: "\EA0A" }
.icon-info:before { content: "\EA0B" }
.icon-twitter-like:before { content: "\EA0C" }
.icon-twitter-retweet:before { content: "\EA0D" }
.icon-twitter-reply:before { content: "\EA0E" }
.icon-twitter-more:before { content: "\EA0F" }
.icon-globe:before { content: "\EA10" }
.icon-facebook-reply:before { content: "\EA11" }
.icon-facebook-comment:before { content: "\EA12" }
.icon-facebook-like:before { content: "\EA13" }
.icon-linkedin-like:before { content: "\EA14" }
.icon-chart:before { content: "\EA15" }
.icon-flag:before { content: "\EA16" }
.icon-pause:before { content: "\EA17" }
.icon-play:before { content: "\EA18" }
.icon-cc-active:before { content: "\EA19" }
.icon-next:before { content: "\EA1A" }
.icon-volume-max:before { content: "\EA1B" }
.icon-volume-medium:before { content: "\EA1C" }
.icon-volume-small:before { content: "\EA1D" }
.icon-cc:before { content: "\EA1E" }
.icon-close:before { content: "\EA1F" }
.icon-facebook:before { content: "\EA20" }
.icon-google-plus:before { content: "\EA21" }
.icon-share:before { content: "\EA22" }
.icon-twitter:before { content: "\EA23" }
.icon-shrink:before { content: "\EA24" }
.icon-gear:before { content: "\EA25" }
.icon-star-fill:before { content: "\EA26" }
.icon-person:before { content: "\EA27" }
.icon-music:before { content: "\EA28" }
.icon-product:before { content: "\EA29" }
.icon-trivia:before { content: "\EA30" }
.icon-caret-down:before { content: "\EA31" }
.icon-caret-up:before { content: "\EA32" }
.icon-close-thin:before { content: "\EA33" }
.icon-play-outline:before { content: "\EA34" }
.icon-swap:before { content: "\EA35" }
.icon-caret-left:before { content: "\EA36" }
.icon-scene:before { content: "\EA37" }
.icon-empty-state:before { content: "\EA38" }
.icon-vai:before { content: "\EA39" }
.icon-vai-sm:before { content: "\EA40" }
.icon-caret-down-thin:before { content: "\EA41" }
.icon-caret-up-thin:before { content: "\EA42" }
.icon-pinterest:before { content: "\EA43" }
.icon-share-outline:before { content: "\EA44" }
.icon-facebook-outline:before { content: "\EA45" }
.icon-pinterest-outline:before { content: "\EA46" }
.icon-google-plus-outline:before { content: "\EA47" }
.icon-twitter-outline:before { content: "\EA48" }


@mixin icon($icon, $font-size:false, $extend-base:true) {
	@extend .icon-#{$icon}:before;
	@if($font-size) {
		font-size: $font-size;
  		font-family: "icons";
	}
	@if($extend-base) {
		@extend .iconcss; 
	}
}