.flip-container {
  perspective: 1000px;

  &--flipped .flipper {
    transform: rotateY(180deg);
  }
}




.flip-container, .front, .back {
  width: 320px;
  height: 480px;
}

.flipper {

  transition: 0.9s $easing1;
  transform-style: preserve-3d;
  position: relative;

  .front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;

    .mask {
      cursor: pointer;
      border-radius: $border-radius;
      height: 451px;
      overflow: hidden;
      

      &:after {
        position: absolute;
        content: '';
        width: 320px;
        height: 480px;
        transform: translateY(-480px);
        background: linear-gradient(0deg, rgba(darken($color-black, 10%),1), rgba(darken($color-black, 10%),0.55) 80%, rgba(darken($color-black, 10%),0.12));
        opacity: 0.12;
        transition: opacity 0.6s $easing1;
      }

      a {
        width: 320px;
        height: 60px;
        align-items: center;
        z-index: 2;
        position: absolute;
        display: flex;
        color: darken($color-white, 2%);
        flex-direction: row;
        justify-content: center;
        transform: translateY(-48px);
        opacity: 0;
        transition: transform $easing1 0.3s, opacity $easing1 0.3s;
        background: linear-gradient(0deg, rgba(darken($color-black, 10%),0.8), rgba(darken($color-black, 10%),0.12) 80%, rgba(darken($color-black, 10%),0));

        i {
          margin-right: 12px;
          font-size: 19px;
        }

        span {
          font-size: 18px;
          font-weight: 300;
          padding-bottom: 2px;
          // border-bottom: solid 1px rgba(darken($color-white, 2%), 0.33);
        }

        span, i {
          opacity: 0.8;
          text-shadow: 1px 1px rgba(darken($color-black, 10%), .12);
          transition: opacity $easing1 0.3s;
        }

        &:hover {
          span, i {
            opacity: 1;
          }
        }
      }
    }
  }
  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }
  .back {
    transform: rotateY(180deg);
  }
  &:hover {
  
    .starred {
  
  &:before {
    position: absolute;
    content: '';
    background-image: radial-gradient(circle at 100% 0%, rgba(darken($color-black, 10%),0.55), rgba(darken($color-black, 10%),0) 48%);
    height: 170px;
    width: 170px;
    transform: translate(20px,-20px);
    display: block;
    z-index: -1;
    right: 0;
    top: 0;

         }
 }

    .mask {
      &:after {
        opacity: 0.33;
      }
      a {
        transition: transform $easing1 0.3s, opacity $easing1 0.6s;
        transform: translateY(-60px);
        opacity: 1;
      }
    }
  }
}


