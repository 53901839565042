
.input-container {
position: relative;
z-index: 1000;
}


#imgurl {
position: absolute;
top: 100px;
background-color: #000000;
color: #ffffff;
}

#imgbtn {
position: absolute;
top: 80px;
background-color: #000000;
color: #ffffff;
}

#message {

position: absolute;
top: 150px;
left: -350px;
background-color: #000000;
color: #ffffff;

}


#messageValue {
position: absolute;
top: 150px;
left: -200px;
background-color: #000000;
color: #ffffff;

}


form {
	position: absolute;
top: 130%;
background-color: #000000;
color: #ffffff;
left: 30%;
}